/* global $ */
import React from 'react'
import { FabricImage, Canvas, util } from 'fabric'

class SeeOnMyHousePreviewMobile extends React.Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()
  }

  componentDidMount() {
    this.canvasRender()
  }

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve({ height: img.height, width: img.width })
      img.onerror = reject
      img.src = src
      console.log(img.height, img.width)
      return img
    })
  }

  canvasRender = async () => {
    try {
      var myImg = document.querySelector('#background-mobile')
      const response = await this.addImageProcess(myImg.getAttribute('src'))

      var realWidth = myImg.clientWidth //myImg.naturalWidth;
      var realHeight = response.height * (realWidth / response.width) //myImg.naturalHeight;

      let canvas = new Canvas('my-door-mobile', { selection: true })
      // fabric.textureSize = 3000
      canvas.on('object:scaling', onObjectScaled)

      function onObjectScaled(e) {
        var scaledObject = e.target
      }
      canvas.setDimensions({ width: realWidth, height: realHeight })
      const imgElement = await util.loadImage(this.props.image)

      const img = new FabricImage(imgElement)
      img.set({
        left: 0,
        top: 0,
        selectable: true,
        lockScalingX: false,
        lockScalingY: false,
      })
      img.scaleToHeight(100)
      img.scaleToWidth(100)
      canvas.add(img)

      // canvas.renderAll();
    } catch (error) {
      console.log(error)
    }
  }

  changeBackground = (e) => {
    let files = e.target.files
    // console.log(files)
    if (files.length > 0) {
      let url = URL.createObjectURL(files[0])
      document.getElementById('background-mobile').src = url
      this.props.setMyDoorEditor(true)
    }
  }

  render() {
    return (
      <div
        className="summary__img summary-preview gallery mobile"
        id={'html2png'}
      >
        <div className="summary__img-box">
          <img
            id={'background-mobile'}
            crossOrigin="anonymous"
            src={this.props.activeHouse.image}
            alt=""
          />
        </div>
      </div>
    )
  }
}

export default SeeOnMyHousePreviewMobile
